/* @font-face {
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Ultra-Regular.ttf");
} */
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/ABCDiatypeSemi-Mono-Regular.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/ABCDiatypeSemi-Mono-RegularItalic.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ABCDiatypeSemi-Mono-Regular.woff");
}
/* @font-face {
  font-family: 'ABCDiatypeSemi-Mono';
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/ABCDiatypeSemi-Mono-RegularItalic.ttf");
} */
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/ABCDiatypeSemi-Mono-Medium.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/ABCDiatypeSemi-Mono-MediumItalic.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/ABCDiatypeSemi-Mono-Medium.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: italic;
  font-weight: 600;
  src: url("./fonts/ABCDiatypeSemi-Mono-MediumItalic.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/ABCDiatypeSemi-Mono-Medium.woff");
}
@font-face {
  font-family: 'ABCDiatypeSemiMono';
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/ABCDiatypeSemi-Mono-MediumItalic.woff");
}