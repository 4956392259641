.masked-overflow {

  /* mask fade distance, for use in mask calculations */
  --mask-height: 64px;

  /* If content exceeds height of container, overflow! */
  overflow-y: auto;

  /* Need to make sure container has bottom/top space,
otherwise content at the bottom/top is always faded out */
  padding-bottom: var(--mask-height);
  padding-top: calc(var(--mask-height) / 2);

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
      to bottom,
      transparent,
      black calc(var(--mask-height) / 2),
      black calc(100% - var(--mask-height)),
      transparent
  );

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content);
  mask-size: var(--mask-size-content);

  /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
}